<template>
  <v-card>
    <v-toolbar
      dense
      card
    >
      <v-icon :color="spClass">
        {{ spIcon }}
      </v-icon>
      <v-toolbar-title>Add New SP</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container
          fluid
          grid-list-xs
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <msisdn
                v-model="item.msisdn"
                :existing="false"
                :rules="msisdnRules"
                hint="Locate Msisdn on the simpack"
                label="Enter Msisdn"
                required
              />
            </v-flex>
            <v-flex xs12>
              <iccid
                v-model="item.iccid"
                :rules="iccidRules"
                hint="Locate serial number on the simpack"
                label="Enter serial number"
                required
              />
            </v-flex>
            <v-flex xs12>
              <black-packages v-model="item.plan" />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        flat
        color="primary"
        @click.native="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        flat
        color="primary"
        @click.native="addRegistrationItem"
      >
        Add New SP
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  spClass,
  spIcon,
} from '@/utils'
import { createGetParams } from '@/rest'

export default {
  components: {
    BlackPackages: () => import(/* webpackChunkName: "blackPackages" */ '@/components/BlackPackages'),
    Iccid: () => import(/* webpackChunkName: "iccid" */ '@/components/Iccid'),
    Msisdn: () => import(/* webpackChunkName: "msisdn" */ '@/components/Msisdn'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      item: {
        // chargeable: 0.00,
        detail: null,
        iccid: null,
        icon: null,
        iconClass: null,
        msisdn: null,
        plan: {},
        // sellingPrice: 0.00
      },
      maxWidth: '390px',
      msisdnRules: [val => !!val || 'Msisdn required!'],
      iccidRules: [val => !!val || 'Iccid required!'],
      inputError: null,
      source: null,
      spClass: spClass,
      spIcon: spIcon,
      valid: true,
    }
  },
  watch: {
    'item.msisdn': function (val) {
      this.cancelRequest()
      if (!this.item.iccid || !val || (val.startsWith('011') && val.length < 11) || val.length < 10) return
      if (this.item.iccid.length < 18) return
      this.validateSp()
    },
    'item.iccid': function (val) {
      this.cancelRequest()
      if (!this.item.msisdn || !val || val.length < 18) return
      if ((this.item.msisdn.startsWith('011') && this.item.msisdn.length < 11) ||
        (this.item.msisdn.length < 10)) return
      this.validateSp()
    },
    dialog: function (val) {
      this.item.detail = this.item.iccid = this.item.icon =
        this.item.iconClass = this.item.msisdn = null
      this.item.plan = {}
      this.$refs.form.reset()
    },
  },
  mounted: function () {
    this.msisdnRules.push(val => !this.inputError || this.inputError)
    this.iccidRules.push(val => !this.inputError || this.inputError)
  },
  methods: {
    cancelRequest: function () {
      this.inputError = null
      if (this.source) this.source.cancel('Forced cancellation.')
      this.source = null
    },
    addRegistrationItem: function () {
      if (!this.$refs.form.validate()) return
      this.item.detail = this.item.msisdn + ', ' + this.item.plan.code
      this.item.icon = this.spIcon
      this.item.iconClass = spClass
      // this.item.chargeable = getNumeralFromBlackCode(this.item.plan.code)
      const localValue = this.value
      localValue.push(Object.assign({}, this.item))
      this.$emit('input', localValue)
      this.editIndex = -1
      this.closeDialog()
    },
    closeDialog: function () {
      this.$emit('update:dialog', false)
    },
    validateIccid: function (cancelToken) {
      const params = createGetParams({
        cancelToken: cancelToken,
        iccid: this.item.iccid,
      })
      return this.$rest.get('validateIccid.php', params)
    },
    validateMsisdnRange: function (cancelToken) {
      const params = createGetParams({
        cancelToken: cancelToken,
        msisdn: this.item.msisdn,
      })
      return this.$rest.get('validateMsisdnRange.php', params)
    },
    validateSp: function () {
      this.source = this.$rest.CancelToken.source()

      this.$rest.all([this.validateIccid(this.source), this.validateMsisdnRange(this.source)])
        .then(this.$rest.spread((iccidResponse, msisdnResponse) => {
          const msisdn = String(iccidResponse.data)
          if (!msisdn || msisdn.indexOf(this.item.msisdn) === -1) {
            this.inputError = 'SP details mismatched!'
          }
        }).bind(this)).catch(e => {
          if (this.$rest.isCancel(e)) return

          if (e.response && e.response.status === 404) {
            this.inputError = e.response.data
          } else {
            this.inputError = e.message
          }
          this.$refs.form.validate()
        })
    },
    /*
    validateIccidOld: function () {
      const params = createGetParams({
        iccid: this.item.iccid
      })

      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.$rest.get('validateIccid.php', params)
        .then(response => {
          const msisdn = String(response.data)
          if (!msisdn || msisdn.indexOf(this.item.msisdn) == -1) {
            this.inputError = 'SP details mismatched!'
          }
        }).catch(e => {
          if (this.$rest.isCancel(e)) return

          if (e.response && e.response.status === 404) {
            this.inputError = 'No such Iccid!'
          } else {
            this.inputError =  e.message
          }
        })
    }
    */
  },
}
</script>
